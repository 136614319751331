<template>
<b-modal id="signupModal" title hide-footer>
    <div class="modal-body">
        <div class="login-head text-center mb-4">
            <h3>Create Account</h3>
            <p>Fill the form below to create a new account.</p>
        </div>
        <form>
            <div class="form-row">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <!-- <label for="">Name</label> -->
                        <input v-validate="{ required : true, min: 5, regex: /^[a-zA-Z0-9]+[\w\-\_\.]+[a-zA-Z0-9]$/}" data-vv-validate-on="blur" label="Name" name="Username" placeholder="Username" @change="usernameChange()" v-model="username" class="form-control" />
                        <span class="text-danger text-sm">{{
                errors.first("Username")
              }}</span>
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <input v-validate="'required|email'" data-vv-validate-on="blur" name="email" type="email" label="Email" placeholder="Email" v-model="registeration_email" class="form-control" />
                        <span class="text-danger text-sm">{{
                errors.first("email")
              }}</span>
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <input ref="password" type="password" data-vv-validate-on="blur" v-validate="'required|min:8|max:32'" name="password" label="Password" placeholder="Password" v-model="registeration_password" class="form-control" />
                        <span class="text-danger text-sm">{{
                errors.first("password")
              }}</span>
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <input type="password" v-validate="'min:6|max:32|confirmed:password'" data-vv-validate-on="blur" data-vv-as="password" name="confirm_password" label="Confirm Password" placeholder="Confirm Password" v-model="confirm_password" class="form-control" />
                        <span class="text-danger text-sm">{{
                errors.first("confirm_password")
              }}</span>
                    </div>
                </div>
            </div>
            <!-- <div class="form-row">
          <div class="col">
            <div class="form-group">
              <select
                name="hearAboutUs"
                v-model="hearAboutUs"
                label="How do you hear about us ?"
                placeholder="How do you hear about us ?"
                class="form-control"
              >
                <option value="">How do you hear about us ?</option>
                <option
                  :key="item.value"
                  v-for="item in hearAboutUsOptions"
                  v-bind:value="item.value"
                >
                  {{ item.value }}
                </option>
              </select>
              <span class="text-danger text-sm">{{
                errors.first("hearAboutUs")
              }}</span>
            </div>
          </div>
        </div> -->
            <div class="form-row">
                <div class="col">
                    <div class="form-group">
                        <input v-if="hearAboutUs === 'Other'" label="Please specify if other" name="hearAboutUsOther" placeholder="Please specify if other" v-model="hearAboutUsOther" class="form-control" />
                        <span class="text-danger text-sm">{{
                errors.first("hearAboutUsOther")
              }}</span>
                    </div>
                </div>
            </div>
            <div class="form-group form-check mb-4">
                <input v-model="isTermsConditionAccepted" type="checkbox" class="form-check-input" />
                <label class="form-check-label text-small" for="rememeber">I accept the <a href="/legal" target="_blank">Privacy Policy & User Agreement</a></label>
            </div>

            <div class="form-group">
                <button type="button" @click="registerUser()" :disabled="!validateRegisterForm" class="btn btn-lg btn-primary btn-block">
                    Sign Up
                </button>

            </div>
        </form>
        <div class="text-center mt-5 google-fb-sign">
            <img src="@/assets/images/pages/btn_google_signin_dark_pressed_web@2x.png" height="52" alt="" class="cursor-pointer" @click="loginWithGoogle();">
            <!-- <a href="#" class="ml-4"><img src="@/assets/images/pages/FB.png" alt="" @click="loginWithFacebook"></a> -->
        </div>
        <div class="text-center google-fb-sign mt-2">
            <img v-if='!isShow' src="@/assets/images/pages/apple-signin-btn.png" height="46" alt="" class="cursor-pointer" @click="loginWithApple">
        </div>
    </div>
</b-modal>
</template>

<script>
import axios from 'axios'
import {
    Client
} from "@hubspot/api-client";
export default {
    data() {
        return {
            registeration_email: '',
            registeration_password: '',
            checkbox_remember_me: false,
            username: '',
            confirm_password: '',
            isTermsConditionAccepted: true,
            studyStatusSelect: [{
                    value: 'Student'
                },
                {
                    value: 'Alumni'
                },
                {
                    value: 'Future Student'
                }
            ],
            hearAboutUsOptions: [{
                    value: 'Partners'
                },
                {
                    value: 'Universities'
                },
                {
                    value: 'Influencer'
                },
                {
                    value: 'Social media'
                },
                {
                    value: 'Other'
                }
            ],
            hearAboutUs: '',
            hearAboutUsOther: '',
            isShow: false
        }
    },
    computed: {
        validateRegisterForm() {
            const isValid = !this.errors.any() &&
                this.username !== '' &&
                this.registeration_email !== '' &&
                this.registeration_password !== '' &&
                this.confirm_password !== '' &&
                this.confirm_password === this.registeration_password &&
                // (this.hearAboutUs !== '' ||
                // (this.hearAboutUs === 'Other' && this.hearAboutUsOther !== '')) &&
                this.isTermsConditionAccepted === true

            return isValid
        }
    },

    created() {
        this.checkConnection()
    },
    methods: {

        checkConnection() {
            if (navigator.userAgent.match(/android/i)) {

                this.isShow = true
                console.log(this.isShow)
            } else {
                this.isShow = false
                console.log(this.isShow)
            }
        },

        usernameChange() {
            this.username = this.username.split(' ').join('_');
        },

        checkLogin() {
            if (this.$store.state.auth.isUserLoggedIn()) {
                this.$vs.notify({
                    title: 'Login Attempt',
                    text: 'You are already logged in!',
                    iconPack: 'feather', 
                    icon: 'icon-alert-circle',
                    position: 'top-right',
                    color: 'warning'
                })
                return false
            }
            return true
        },
        registerUser() {
            if (!this.validateRegisterForm || !this.checkLogin()) return

            const payload = {
                userDetails: {
                    username: this.username,
                    email: this.registeration_email.toLowerCase(),
                    password: this.registeration_password,
                    isPartner: false,
                    confirmPassword: this.confirm_password,
                    hearAboutUs: this.hearAboutUs,
                    hearAboutUsOther: this.hearAboutUsOther,
                    router: this.$router 
                },
                notify: this.$vs.notify
            }

            this.$store.dispatch('auth/registerUser', payload).then(() => {
                this.$bvModal.hide('signupModal')
                this.$vs.loading.close()
            })

            this.username = ''
            this.registeration_email = ''
            this.registeration_password = ''
            this.confirm_password = ''
            this.hearAboutUs = ''
            this.hearAboutUsOther = ''
        },
        loginWithFacebook() {
            this.$vs.loading()
            this.$store.dispatch('auth/loginWithFacebook')
        },
        loginWithApple() {
            this.$vs.loading()
            this.$store.dispatch('auth/loginWithApple')
        },
        loginWithGoogle() {
            this.$vs.loading()
            this.$store.dispatch('auth/loginWithGoogle')
        },

    }
}
</script>

<style scoped>
</style>
